@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --bg-color: #ffffff;
  --font-color: #1c1c1d;
  --primary-color-50: #f4eaeb;
  --primary-color-100: #e1cbcd;
  --primary-color-200: #d6b9bb;
  --primary-color-300: #d4b7bb;
  --secondary-color-100: #525161;
  --secondary-color-200: #464759;
  --secondary-color-300: #353648;
  --secondary-color-variant: #736d77;
  --tertiary-color-100: #d8ddd7;

  --base-font-family: 'Open Sans', sans-serif;
  --deco-font-family: "Roboto", serif;

  --xxs-font-size: .9rem;
  --xs-font-size: 1rem;
  --s-font-size: 1.1rem;
  --m-font-size: 1.25rem;
  --l-font-size: 1.5rem;
  --xl-font-size: 1.75rem;
  --xxl-font-size: 2.25rem;
  --xxxl-font-size: 3rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  border: none;
}

body {
  background-color: var(--primary-color-50);
  color: var(--font-color);
  font-family: var(--base-font-family);
  font-size: var(--s-font-size);
}

a {
  color: var(--secondary-color-100);
  transition: all .3s ease-in-out;
}

a:hover {
  color: var(--primary-color-100);
}

img {
  width: 100%;
}

p {
  line-height: 1.85rem;
}

b {
  color: var(--secondary-color-300);
}

h1 {
  font-size: var(--xxxl-font-size);
  color: var(--primary-color-300);
  text-transform: uppercase;
  font-weight: 900;
}

h2 {
  color: var(--secondary-color-300);
  font-size: var(--xxl-font-size);
  position: relative;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 2.25rem;
  margin-bottom: 1.25rem;
}

h3 {
  font-size: var(--l-font-size);
  background-color: var(--secondary-color-variant);
  color: var(--primary-color-50);
  text-align: center;
  padding: 6px;
}

small {
  font-size: var(--m-font-size);
  color: var(--secondary-color-100);
}

header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 999;
}

footer {
  background-color: var(--primary-color-100);
  color: var(--secondary-color-300);
  padding: 1rem;
}

nav {
  color: var(--primary-color-100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  bottom: 0;
  text-align: left;
  font-size: 24px;
  transition: .3s;
}



/* Újrafelhasználóható osztályok */
h2 span {
  color: var(--primary-color-100);
  letter-spacing: 10px;
  cursor: default;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: .5;
  user-select: none;
  white-space: nowrap;
}

.btn.btn-custom {
  background-color: var(--secondary-color-100);
  color: var(--primary-color-300);
  font-family: var(--base-font-family);
  font-size: var(--s-font-size);
}
.btn.btn-custom:hover {
  background-color: var(--primary-color-300);
  color: var(--secondary-color-100);
}



/* Egyedi osztályok */
#tsparticles canvas {
  z-index: -1;
}


nav ul li {
  display: inline-block;
  margin: 1rem 1.5rem;
}
nav ul li.nav-icon {
  display: inline-block;
  width: 1.7rem;
  opacity: .4;
  transform: scale(.65);
}
nav ul li.nav-icon.active {
  opacity: 1.0;
  transform: scale(1.0);
}
.dark-mode {
  color: var(--secondary-color-100);
}
.dark-mode:hover {
  color: var(--secondary-color-300);
}


.main-img {
  max-width: 400px;
}


.information-box {
  background-color: var(--primary-color-100);
  padding: 10px;
}
.information-mosaic {
  padding-left: 0;
  text-align: center;
}
.information-mosaic li {
  background-color: var(--primary-color-200);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.information-mosaic span {
  font-family: var(--deco-font-family);
  font-size: var(--l-font-size);
  color: var(--secondary-color-300);
  margin: 5px;
}
.information-mosaic li:nth-child(even) {
  background-color: var(--secondary-color-200);
  color: var(--primary-color-100);
}
.information-mosaic li:nth-child(even) span {
  color: var(--primary-color-300);
}
.information-menu {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  justify-content: space-between;
}
.information-menu li {
  background-color: var(--primary-color-200);
  color: var(--secondary-color-300);
  width: 100%;
  text-align: center;
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
}
.information-menu li:hover,
.information-menu li:active,
.information-menu li:focus, 
.information-menu li.active {
  background-color: var(--secondary-color-200);
  color: var(--primary-color-200);
}
.information-menu span {
  display: block;
  font-size: var(--l-font-size);
}


.skills-mosaic {
  padding-left: 0;
}
.skill-box {
  background-color: var(--primary-color-100);
  margin: calc(var(--bs-gutter-x) * .5) 0;
  color: var(--secondary-color-300);
  text-align: center;
  padding: 20px 5px;
  margin-left: 0;
}
.skill-box:hover {
  background-color: var(--primary-color-300);
}
.skill-box span {
  font-size: var(--xxl-font-size);
}
.skill-box p {
  margin-bottom: 0;
}
.skill-box small {
  font-size: var(--xs-font-size);
  color: var(--secondary-color-200);
}


.timeline-data {
  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 1.5rem;
}
.timeline-title {
  font-size: var(--m-font-size);
  font-weight: 600;
  margin-bottom: 0;
}
.timeline-subtitle {
  font-size: var(--xs-font-size);
  color: var(--secondary-color-200);
  display: inline-block;
  margin-bottom: .5rem;
}
.timeline-calendar {
  color: var(--secondary-color-300);
  font-size: var(--xs-font-size);
}
.timeline-calendar svg {
  margin-right: 5px;
}
.timeline-rounder {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: var(--secondary-color-300);
  border-radius: 50%;
}
.timeline-rounder__active {
  box-shadow: 0 0 0 3px var(--primary-color-100),0 0 0 5px var(--secondary-color-300);
}
.timeline-line {
  display: block;
  width: 1px;
  height: 99%;
  background-color: var(--secondary-color-300);
  transform: translate(8px, -7px);
}
.timeline-line__gradient {
  background: linear-gradient(0deg, rgba(0,0,0,0) 20%, var(--secondary-color-300) 100%);
}
.timeline-list {
  font-size: var(--xs-font-size);
  color: var(--secondary-color-300);
  padding-left: 1rem;
}
.timeline-list li {
  list-style: disc;
}


.portfolio-box {
  background-color: var(--primary-color-100);
  color: var(--secondary-color-300);
  font-size: var(--secondary-color-300);
  padding: 10px;
  height: 100%;
}
.portfolio-box h3 {
  color: var(--secondary-color-300);
  background-color: transparent;
  text-align: left;
  text-transform: initial;
  padding: 0;
  margin: .5rem 0;
  word-wrap: break-word;
}
.portfolio-box p {
  margin-bottom: 0;
}
.portfolio-box .btn.btn-custom {
  margin-top: .75rem;
}
.portfolio-box ul {
  padding-left: 0;
  margin-bottom: .25rem;
}
.portfolio-box ul li {
  background-color: var(--secondary-color-300);
  color: var(--primary-color-300);
  font-size: var(--xxs-font-size);
  display: inline-block;
  text-transform: uppercase;
  margin: 0 .25rem .25rem 0;
  padding: 5px;
}
.portfolio-box ul li::before {
  content: "#";
}


.scrollup {
  background-color: var(--primary-color-300);
  position: fixed;
  right: 2rem;
  bottom: -20%;
  opacity: .8;
  padding: 0 .3rem;
  padding-bottom: .5rem;
  z-index: 555;
  transition: .3s;
}
.scrollup:hover {
  background-color: var(--primary-color-100);
}
.scrollup-icon {
  color: var(--secondary-color-300);
  font-size: var(--xxl-font-size);
  padding-top: .5rem;

}
.show-scroll {
  bottom: 4.7rem;
}

@media (min-width: 576px) {
  nav {
      align-items: flex-end;
  }

  .nav__menu {
      justify-content: right;
  }

  .nav__item {
      font-size: 1.8rem;
      margin: .5rem 2rem;
  }

  .nav__item--language {
      width: 2rem;
  }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {
  .timeline-calendar {
    width: 200px;
    text-align: right;
  }
}

@media (min-width: 1200px) {
  .timeline-calendar {
    width: 250px;
  }
}

@media (min-width: 1400px) {
  .timeline-calendar {
    width: 300px;
  }
}